/* global bz_app, angular */
bz_app.factory('contentInjectService', [
  '$compile',
  function ($compile) {
    return {
      scope: null,
      location: null,
      neighborhoodProperties: null,
      neighborhoodPropertiesDetails: null,
      propertyMapInit: function ($scope, data) {
        this.scope = $scope
        this.location = data.location
        this.neighborhoodProperties = data.neighborhoodProperties
        this.neighborhoodPropertiesDetails = data.neighborhoodPropertiesDetails

        if (document.readyState === 'complete') {
          this.propertyMap()
        } else if (window.addEventListener) {
          window.addEventListener('load', this.propertyMap.bind(this), false)
        } else if (window.attachEvent) {
          window.attachEvent('onload', this.propertyMap.bind(this))
        }
      },
      propertyMap: function () {
        angular
          .element('.cities-permit-map-wrapper')
          .prepend(
            '<div id="cities_permit_map" permit-map ' +
              'properties="' +
              encodeURI(JSON.stringify(this.neighborhoodProperties)) +
              '"' +
              'latitude="' +
              this.location.lat +
              '"' +
              'longitude="' +
              this.location.lng +
              '"' +
              'location-id="' +
              this.location.id +
              '"' +
              'require-contractor="false"' +
              'listen-for-updates="false">' +
              '</div>'
          )

        $compile(angular.element('#cities_permit_map'))(this.scope)

        var neighborhood_projects = $compile(
          '<div neighborhood-projects ' +
            'property-id="null" ' +
            'version="v2" ' +
            'properties="$ctrl.neighborhoodPropertiesDetails" ' +
            'class="property-neighborhood-items container v2"></div>'
        )(this.scope)

        angular
          .element('.neighborhood-items-container')
          .append(neighborhood_projects)
      },
    }
  },
])
