/* global bz_app, angular */
bz_app.directive('testimonialComments', function () {
  return {
    restrict: 'AE',
    scope: {},
    link: function (scope, element) {
      element.find('.comments-item').mouseover(function () {
        angular.element('.comments-item').removeClass('comments-item-selected')
        angular.element(this).addClass('comments-item-selected')

        angular
          .element('span[class*=comments-quote-]')
          .removeClass('comments-quote-selected')
        angular
          .element('.comments-quote-' + this.id)
          .addClass('comments-quote-selected')
      })

      element.find('.comments-item').click(function (e) {
        e.preventDefault()
      })
    },
  }
})
