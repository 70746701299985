bz_app.directive('scrollReveal', [
  '$window',
  '$timeout',
  function ($window, $timeout) {
    return {
      restrict: 'A',
      scope: {
        topThreshold: '=?',
        bottomThreshold: '=?',
        memoizeOffset: '=?',
        revealedCallback: '&',
        perpetuate: '=?',
      },
      link: function ($scope, element, attrs) {
        $scope.init = function () {
          if (!isMobile.check()) {
            if (typeof this.perpetuate === 'undefined') {
              this.perpetuate = true
            }
            element.addClass('scroll-reveal')
            this.setElementAttributes()
            this.setListeners()
          }
        }

        $scope.setDefaults = function () {
          this.topThreshold = this.topThreshold || this.elementHeight / 3
          // offset default for navbar
          this.bottomThreshold =
            this.bottomThreshold || this.elementHeight / 3 + 52
          if (typeof this.memoizeOffset == 'undefined') {
            this.memoizeOffset = true
          }
          this._scrolled = false
        }

        $scope.setListeners = function () {
          $timeout(
            function () {
              this.deactivateListener = this.$on(
                'animFrameReady',
                this.checkReveal.bind(this)
              )
            }.bind(this)
          )

          angular
            .element($window)
            .on('resize', this.setElementAttributes.bind(this))
        }

        $scope.checkReveal = function () {
          if (this.offTop() || this.offBottom()) {
            element[0].classList.remove('revealed')
          } else {
            element[0].classList.add('revealed')
            this.revealedCallback && this.revealedCallback()

            if (!this.perpetuate) {
              this.deactivateListener()
            }
          }
        }

        $scope.amountTopShown = function () {
          return $window.pageYOffset + this.windowHeight - this.elementOffset()
        }

        $scope.amountBottomShown = function () {
          return this.elementOffset() + this.elementHeight - $window.pageYOffset
        }

        $scope.offBottom = function () {
          return this.amountTopShown() < this.topThreshold
        }

        $scope.offTop = function () {
          return this.amountBottomShown() < this.bottomThreshold
        }

        $scope.setElementAttributes = function () {
          $timeout(
            function () {
              if (this.memoizeOffset) {
                this._elementOffset = element.offset().top
              }
              this.elementHeight = element.height()
              this.windowHeight = $window.innerHeight
              this.setDefaults()
            }.bind(this),
            0
          )
        }

        $scope.elementOffset = function () {
          return this._elementOffset || element.offset().top
        }

        $scope.init()
      },
    }
  },
])
