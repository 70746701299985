const PressReviewsCtrl = function () {
  this.$onInit = () => {
    this.selectedReview = 'techcrunch'
    this.temporaryReview = null
  }

  this.selectReview = (reviewName) => (this.selectedReview = reviewName)
  this.selectTemporaryReview = (reviewName) =>
    (this.temporaryReview = reviewName)
  this.deselectTemporaryReview = () => (this.temporaryReview = null)

  this.reviewToShow = () => this.temporaryReview || this.selectedReview
}

export default PressReviewsCtrl
