/* global angular, defaultAngularModuleSet */
import ShowcaseProjectsCarousel from '../_components/angular/components/showcase_projects_carousel_module'
import HowItWorksCtrl from './controllers/how_it_works_controller'
import DiscoverContractorsCtrl from './controllers/discover_contractors_controller'
import PressReviewsCtrl from './controllers/press_reviews_controller'

const requiredModules = [
  'bzSearchJumboTronModule',
  'bzMapModule',
  ShowcaseProjectsCarousel,
  ...defaultAngularModuleSet,
]

const homepageApp = angular.module('homepageApp', requiredModules)

homepageApp
  .controller('howItWorksCtrl', HowItWorksCtrl)
  .controller('discoverContractorsCtrl', DiscoverContractorsCtrl)
  .controller('pressReviewsCtrl', PressReviewsCtrl)

window.bz_app = homepageApp

angular
  .element(document)
  .ready(() => angular.bootstrap(document, ['homepageApp']))
