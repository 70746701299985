/* global bz_app */
bz_app.controller('lazyLoadPropertyMapCtrl', [
  '$scope',
  'contentInjectService',
  'positionService',
  function ($scope, contentInjectService, positionService) {
    this.init = function (data) {
      positionService.triggerInViewport(
        '.cities-permit-map-wrapper',
        () => {
          this.initMap(data)
          Track.sendGa('Google Map', 'homepage')
        },
        { checkForMap: true }
      )
    }

    this.initMap = (data) => {
      if (
        data &&
        data.location &&
        data.neighborhoodProperties &&
        data.neighborhoodPropertiesDetails
      ) {
        contentInjectService.propertyMapInit($scope, data)
        this.neighborhoodPropertiesDetails = data.neighborhoodPropertiesDetails
      }
    }
  },
])
